.podcast-player-container {
  .podcast-player {
    position: absolute;
    display: grid;
    justify-items: center;

    width: 100%;
    height: 374px;
    left: 0px;
    top: 0px;

    border-radius: 0px;
    background: rgba(84, 84, 84, 0);
    .artwork {
      position: absolute;
      width: 400px;

      border-radius: 0px;
    }
    .player-title {
      position: absolute;
      width: 236px;
      height: 60px;
      top: 80px;

      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 30px;
      align-self: center;

      text-align: center;

      color: #fff;
    }
    .player-author {
      position: absolute;
      width: 167px;
      height: 24px;
      top: 210px;

      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 23.8px;
      align-self: center;

      text-align: center;

      color: #b5bac1;
    }
    .player-buttons {
      position: absolute;

      top: 254px;
      width: 167px;
      height: 51px;

      display: flex;
      align-items: center;
      justify-content: space-between;
      .fb-button {
        border: none;
        background: none;
        width: 18px;
        height: 18px;
        margin: 0;
        padding: 0;
      }
      .play-button-container {
        top: 0px;
        left: 35%;
      }
    }
  }
}
.podcast-player-container {
  display: grid;
  align-items: center;
  justify-items: center;
  .podcast-features {
    position: absolute;
    top: 450px;
    width: 309px;
    height: 60px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    border-bottom: 1px solid #898f975c;

    .counts {
      font-weight: 500;
      font-size: 14px;
      color: #fff;
      color: #fff;
    }
    .duration {
      margin-left: 30px;
      margin-right: 30px;

      font-weight: 900;
      font-size: 14px;
      color: #fff;
    }
    .social-button {
      border: none;
      background: none;
    }
  }
  .podcast-info {
    position: absolute;
    top: 540px;
    width: 309px;

    display: grid;
    align-items: center;
    justify-content: space-between;
    .info-items {
      display: flex;

      align-items: center;
      justify-content: space-between;

      .info-logos {
        display: grid;
        align-items: center;
        justify-items: center;
        height: 32px;
        width: 32px;

        margin-right: 10px;

        background: #19232f;
        border-radius: 50%;
      }
      .info-texts {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;

        margin-left: 10px;
        margin-right: 30px;

        display: flex;
        align-items: center;

        color: #fff;
      }
      .tree-dots {
        margin-left: 50px;
      }
    }
  }
  .podcast-summary {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 22.1px;
    color: #898f97;

    margin-top: 43px;
  }
}

.progress-bar {
  position: absolute;
  width: 309px;
  top: 408px;
  background: -webkit-gradient(
    linear,
    0% 0%,
    100% 0%,
    from(rgb(255, 255, 255)),
    from(rgb(119, 119, 119))
  );
}
