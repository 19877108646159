.login-main {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;

  .logo {
    width: 156.88px;
    height: 72px;
    left: 33px;
    top: 51px;
  }
}

.background {
  width: 100%;
}

.frame {
  position: absolute;
  opacity: 0.5;
}
.login-title {
  position: absolute;
  width: 195px;
  height: 60px;
  top: 171px;

  margin-left: 33px;

  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 125%;

  color: #ffffff;

  z-index: 200;
}
.login-background {
  position: absolute;
  left: 0%;
  top: 0%;

  height: 780px;
  width: 360px;

  background: #09121c;
  border-radius: 0px 0px 24px 0px;
}
