.play-button-container {
  position: absolute;
  display: grid;

  .play-button {
    display: grid;
    align-items: center;
    justify-content: center;

    width: 51px;
    height: 51px;

    border: none;
    border-radius: 50%;
    background: #ff334b;
  }
}
