a {
  text-decoration: none;
}
.podcast-container {
  display: grid;
  width: 309px;
  height: 180px;
  margin-bottom: 20px;

  border-radius: 24px 24px 0px 24px;

  .podcast-name {
    display: grid;

    width: 250px;
    height: 60px;

    margin-top: 29px;
    margin-left: 32px;

    font-style: normal;
    font-weight: 500;
    font-size: 19px;

    color: #ffffff;
    z-index: 100;
  }
  .podcast-date {
    width: 145px;
    height: 22px;

    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 170%;

    color: #898f97;
    .clock-icon {
      margin-left: 6px;
      margin-right: 6px;
    }
  }
  .author-image {
    width: 16px;
    height: 16px;

    margin-top: 8px;
    padding: 0;

    border-radius: 50%;
  }
  .podcast-author {
    margin-top: -18px;
    margin-left: 25px;

    width: 100px;
    height: 22px;

    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 170%;

    color: #ffffff;
  }

  .podcast-background {
    position: absolute;
    display: grid;
    width: 309px;
    height: 180px;

    margin-bottom: 20px;
    border-radius: 24px 24px 0px 24px;

    opacity: 0.5;
    z-index: -1;
  }
  .date-container {
    position: absolute;
    margin-top: 113px;
    margin-left: 32px;
    display: grid;
  }
}
