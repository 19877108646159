.login-container {
  width: 100%;
  height: 100%;
  z-index: 1;

  .login-form {
    display: grid;
    justify-items: center;
    align-items: center;
    justify-content: space-around;
    .input-login,
    .input-password {
      box-sizing: border-box;

      position: absolute;
      width: 276px;
      height: 58px;
      left: 33px;

      border: 1px solid rgba(255, 255, 255, 0.15);
      border-radius: 16px 16px 0px 16px;

      text-indent: 64px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 125%;
      color: #898f97;
    }
    .input-login {
      top: 303px;

      background: #09121c url('../../images/mailbox.svg') no-repeat left 26.67px
        center;
    }
    .input-password {
      top: 377px;

      background: #09121c url('../../images/key.svg') no-repeat left 26.67px
        center;
    }
    .login-button {
      position: absolute;
      width: 276px;
      height: 51px;
      left: 33px;
      top: 465px;

      background: #3369ff;
      box-shadow: 0px 5px 50px rgba(51, 105, 255, 0.7);
      border-radius: 99px;
      border: none;

      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 170%;
      color: #fff;
    }
  }
}
