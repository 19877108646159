.podcast-main {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 1200px;
  background: #09121c;
  .artwork {
    height: 100%;
  }
}
