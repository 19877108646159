.search-input {
  position: absolute;
  width: 312px;
  height: 48px;
  top: 224px;
  left: 32px;

  background: #010304 url(../../images/search-icon.svg) no-repeat right 20px
    center;
  border-radius: 16px;
  border: none;

  text-indent: 20px;
  color: #fff;
}
